import React, { useState } from "react"

const RatingCard = ({ defaultValue = 0, precision = 1, readOnly = false, size = `medium`, onChange }) => {
  const [ rating, setRating ] = useState(defaultValue)

  const handleMouseEnter = value => {
    if (!readOnly) {
      setRating(value)
    }
  }

  const handleClick = value => {
    if (!readOnly) {
      setRating(value)
      if (onChange) {
        onChange(value)
      }
    }
  }

  const getStarSize = () => {
    switch (size) {
    case `small`:
      return `w-4 h-4`
    case `large`:
      return `w-8 h-8`
    default:
      return `w-6 h-6`
    }
  }

  const stars = Array.from({ length: 5 }, (_, index) => {
    const starValue = index + 1
    const isFullStar = rating >= starValue
    const isHalfStar = !isFullStar && rating >= starValue - 0.5

    return (
      <svg
        key={index}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill={isFullStar ? `#FFD700` : isHalfStar ? `url(#half-${index})` : `none`}
        stroke="#FFD700"
        strokeWidth="2"
        className={`cursor-pointer ${getStarSize()} ${
          readOnly ? `cursor-default` : `hover:fill-yellow-400`
        }`}
        onMouseEnter={() => handleMouseEnter(starValue - 0.5)}
        onClick={() => handleClick(starValue - 0.5)}
      >

        {isHalfStar && (
          <defs>
            <linearGradient id={`half-${index}`} x1="0" x2="1" y1="0" y2="0">
              <stop offset="50%" stopColor="#FFD700" />
              <stop offset="50%" stopColor="none" />
            </linearGradient>
          </defs>
        )}
        <path
          d="M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z"
        />
      </svg>
    )
  })

  return <div className="flex space-x-1">{stars}</div>
}

export default RatingCard
