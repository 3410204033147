'use client'
import { getImageUrl } from '@/utils/functions/images/imagesHelper'
import { useView } from '@/utils/functions/view/viewHelper'
import parse from "html-react-parser"
import { CouponList } from '../../coupons'
import { WishList } from '../../wishlist'
import { Labels } from '../Labels'
import CardImage from './CardImage'
import CardPrice from './CardPrice'
import RatingCard from './RatingCard'

const Card = props => {
  const { mobile, domain, element, hostname, colors, product, catalogue, small = false, plus = 0 } = props
  const {
    image, cost, pvp, discount, imgalt, title, brand, score
  } = element
  const { login, business, styles } = hostname
  const cardWidth = mobile || small ? parseInt(styles?.mobileCardWidth, 10) ?? 155 : parseInt(styles?.desktopCardWidth, 10) + plus ?? 250
  const urlThumb = getImageUrl(image, business?.thumb, domain)
  const { ref, view } = useView(hostname)

  return (
    <div ref={ref} style={{ overflow: `unset`, width: `${cardWidth}px` }}
      className={`${styles?.cardBorder ? styles.cardBorder : `card-rounded`} hover:border-gray-100 bg-white border-transparent border-2 m-1 contrastCard relative`} >
      {
        view && <>
          <div style={{ overflow: `unset`, height: cardWidth, width: `100%` }}
            className={`${styles?.cardBorder ? styles.cardBorder : `card-rounded`} p-0 flex items-center justify-center contrastParent relative`}
          >
            <Labels {...props} />
            <CardImage {...{ urlThumb, imgalt, hostname, cardWidth, imgTitle: imgalt }} />
            {login?.user &&
              <WishList {...{
                product, catalogue, hostname, small
              }} />
            }
          </div>
          <div className={`px-3 py-1`}>
            <p style={{ color: colors.darkGrey }} className={`text-xs flex line-clamp-2`}>
              {brand}
            </p>
            <h3 style={{ color: colors.color }} className={`font-medium flex m-0 leading-normal ${small ? `h-[32px] line-clamp-1` : `h-[45px] line-clamp-2`} ${small ? `text-xs` : `text-[14px]`}`}>
              {title}
            </h3>
            <CardPrice {...{ cost, pvp, discount, colors, small, mobile }} />
          </div>
          {!small && <div id="product-rating" className="flex px-2 py-0 mb-1 items-center h-6">
            {!!score?.score && (<>
              <div className=" me-1 items-center ">
                <RatingCard name="half-rating-read" defaultValue={score.score} precision={0.5} readOnly size="small" />
              </div>
              <p style={{ color: colors.darkGrey }} className="text-right text-xs line-clamp-2 font-roboto">
                {parse(`(${score.total})`)}
              </p>
            </>
            )}
          </div>}
          {product?.coupons &&
              <CouponList {...{
                login, product, catalogue, mobile, coupons: product?.coupons, ft: catalogue ? 15 : 12
              }} />
          }
        </>
      }
    </div>
  )
}

export default Card
