'use client'
import styled from "@emotion/styled"
import Image from 'next/image'
import React, { memo, useCallback, useEffect, useState } from 'react'
import { processSvg } from "../functions/images/processSvg"
import { Base64 } from "js-base64"

const SVGImageContainer = styled.div`
  display: block;
  margin: ${({ m }) => m ?? `0 5px`};
  width: ${({ wdth, size }) => wdth ?? size};
  height: ${({ hght, size }) => hght ?? size};
  min-width: ${({ wdth, size }) => wdth ?? size};
  min-height: ${({ hght, size }) => hght ?? size};
  & img {
    width: ${({ wdth, size }) => wdth ?? size};
    height: ${({ hght, size }) => hght ?? size};
  }
`

const IconsSVG = memo(({ path, size = 25, fillcolor = `black`, wdth = null, hght = null, name, m, abbr, ...props }) => {
  const [ svgContent, setSvgContent ] = useState(null)

  const getSvg = useCallback(async() => {
    try {
      // TODO:controlar que no todos los iconos se procesen (crear otro componente IconsSvg que no procese )
      const data = await processSvg(
        path, size, fillcolor, wdth, hght, name
      )
      setSvgContent(data)
    } catch (error) {
      console.error(`Error loading SVG:`, error)
    }
  }, [
    path, size, fillcolor, wdth, hght, name
  ])

  useEffect(() => {
    if (abbr === `native`) {
      const base64 = Base64.atob(path.split(`,`)[1])
      setSvgContent(base64)
    } else {
      getSvg()
    }
  }, [getSvg])

  if (!svgContent) {
    return null
  }
  if (abbr === `native`) {
    return <div className={`svg-class-${name}`} dangerouslySetInnerHTML={{ __html: svgContent }} style={{ width: wdth, objectFit: `contain` }} />
  }

  return (
    <SVGImageContainer size={`${size}px`} {...{ wdth, hght, m }}>
      <Image
        priority
        {...props}
        src={`data:image/svg+xml;utf8,${encodeURIComponent(svgContent)}`}
        alt={`${name}-svg`}
        width={wdth ?? size}
        height={hght ?? size}
        unoptimized={true}
      />
    </SVGImageContainer>
  )
})

export default IconsSVG
