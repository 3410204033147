'use client'
const LoaderBar = ({ color = `#0A0A0A` }) => {
  const classStyle = color ? {
    background: `linear-gradient(to right, ${color}, ${color}, ${color})`,
    height: `100%`,
  } : null
  return classStyle && (
    <div className={`w-full bg-gray-200 rounded-full h-1 overflow-hidden`}>
      <div className="animate-shimmer w-full" style={classStyle}/>
    </div>
  )
}

export default LoaderBar
