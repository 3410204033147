import { types } from "@/types"
import { handleKeyRandom } from "@/utils/functions"
import { colors, constants } from "@/utils/global"
import parse from "html-react-parser"
import {
  useId, useMemo, useRef, useState
} from "react"
import { FaArrowDown, FaArrowUp } from "react-icons/fa"
import { MdImportExport, MdNewReleases } from "react-icons/md"
import { DropDownArrows } from "../../basket/subcomponent/components/subcomponents/DropDownArrows"
import { PopperFilter } from "../desktop"

export const OrderFilterSearch = ({ ...props }) => {
  const {
    texts, filters, sticky, left, motovo, setOrder, hostname
  } = props
  const { mobile } = hostname
  const cataloguePage = useMemo(() => texts?.catalogue_page, [texts])
  const anchorRef = useRef()
  const idRef = useId()
  const [ open, setOpen ] = useState(false)
  const iconStyle = { fontSize: 18 }
  const options = useMemo(() => {
    const { order, ...opt } = types.catalog.order
    const { desc, asc, new: newF } = types.catalog.order
    const tmpOpt = Object.entries(opt).map(x => {
      const tmpElem = {
        [types.catalog.order.desc]: <FaArrowDown style={iconStyle} />,
        [types.catalog.order.asc]: <FaArrowUp style={iconStyle} />,
        [types.catalog.order.new]: <MdNewReleases style={iconStyle} />,
      }
      const element = tmpElem[x[1]]
      return {
        ...constants.OBJ_FILTER,
        type: order,
        parent: types.catalog.get,
        childId: x[1],
        pathParams: [ `GET`, `o`, x[1] ],
        element,
      }
    })
    return tmpOpt
  })
  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return
    }
    setOpen(false)
  }
  const handleChangePath = async item => {
    if (filters.find(x => x.childId === item.childId)) {
      await setOrder()
      return false
    }
    await setOrder(item)
  }
  const MenuItems = () => (
    <>
      {options.map((item, i) => (
        <div
          key={handleKeyRandom(idRef, i)}
          aria-label="static-item"
          onClick={() => handleChangePath(item)}
          disabled={motovo && item.childId === types.catalog.order.new}
          className={filters.find(x => x.childId === item.childId) ? `active` : `no-active`}
          style={{
            fontSize: mobile ? `14px` : `12px`,
            padding: `4px`
          }}
        >
          <input type="checkbox" style={{ color: colors.secondary }} checked={!!filters.find(x => x.childId === item.childId)} />
          &nbsp;
          <span className="">{parse(cataloguePage?.order_list[i] ?? ``)}</span>
        </div>
      ))}
    </>
  )
  const ListMenu = prop => (
    <div
      style={{
        width: `auto`,
        maxWidth: 200,
      }}
    >
      <div id={idRef}
        className="" style={{
          padding: 0,
          maxHeight: `195px`
        }}>
        <MenuItems />
      </div>
    </div>
  )

  return !!cataloguePage && (
    <div
      style={{
        margin: !mobile && `0px 4px 4px 0px`,
      }}
    >
      <button
        id="order-filter"
        ref={anchorRef}
        className={`bg-gray-400  ${mobile}` && sticky ? `is-pinned` : ``}
        aria-controls={open ? `split-filter-order` : undefined}
        aria-expanded={open ? `true` : undefined}
        aria-label="order-filters"
        aria-haspopup=""
        onClick={() => setOpen(!open)}
        open={open}
        {...{ left }}
        style={{ minWidth: 65 }}
      >
        <div
          style={{
            width: `100%`,
            display: `flex`,
            justifyContent: mobile ? `center` : `space-between`,
            alignItems: `center`,
          }}
          className=""
        >
          {filters[0] ? filters[0].element : <MdImportExport style={iconStyle} />}
          <p>
            {cataloguePage.order_by}
          </p>
          <DropDownArrows {...{ open }} />
        </div>
      </button>
      <PopperFilter {...{
        open,
        anchorRef,
        handleClose
      }}>
        <ListMenu />
      </PopperFilter>
    </div>
  )
}
